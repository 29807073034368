import cx from 'clsx'
import React from 'react'
import { useParams } from 'react-router-dom'

import CreateIcpCard from '@/modules/icp/components/CreateIcpCard/CreateIcpCard'
import CreatePersonMemoTaskCard from '@/modules/task/components/CreatePersonMemoTaskCard/CreatePersonMemoTaskCard'
import CreateTranscriptReportTaskCard from '@/modules/task/components/CreateTranscriptReportTaskCard/CreateTranscriptReportTaskCard'
import { InternalTaskType, TaskType } from '@/modules/task/model'
import useUserMe from '@/modules/user/hooks/useUserMe'
import useWorkspace from '@/modules/workspace/hooks/useWorkspace'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import useTaskHighlight from './useTaskHighlight'

const WorkspacePage: React.FC = () => {
  const { workspaceId } = useParams()

  const { data: userMe } = useUserMe()

  const workspaceQuery = useWorkspace(workspaceId!)

  const highlights = useTaskHighlight()

  return (
    <RenderQuery query={workspaceQuery}>
      {(workspace) => {
        const transcriptReportTaskConfig = workspace?.availableTasks.find(
          (task) => task.type === TaskType.TranscriptReport,
        )
        const personMemoTaskConfig = workspace?.availableTasks.find(
          (task) => task.type === TaskType.PersonMemo,
        )
        const createIcpTaskConfig = workspace?.availableTasks.find(
          (task) => task.type === InternalTaskType.CreateIcp,
        )

        return (
          <div className="my-auto flex flex-row flex-wrap justify-center gap-12 px-6 pb-8 pt-8">
            {personMemoTaskConfig ? (
              <CreatePersonMemoTaskCard
                className={cx(highlights[TaskType.PersonMemo])}
                creditsCost={personMemoTaskConfig.creditsCost}
              />
            ) : null}
            {transcriptReportTaskConfig ? (
              <CreateTranscriptReportTaskCard
                className={cx(highlights[TaskType.TranscriptReport])}
                creditsCost={transcriptReportTaskConfig.creditsCost}
              />
            ) : null}

            {createIcpTaskConfig ? (
              <CreateIcpCard
                className={cx(highlights[InternalTaskType.CreateIcp])}
                creditsCost={createIcpTaskConfig.creditsCost}
                freeIcp={userMe?.freeIcp}
                tier={userMe?.planTier}
              />
            ) : null}
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default WorkspacePage
