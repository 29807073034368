import dayjs from 'dayjs'

import { IcpLead, IcpLeadsWithStatus } from '@/modules/icp/model'

import { IcpLeadResponse, IcpLeadsWithStatusResponse } from './icpLeadsApiInterfaces'

export const icpLeadAdapter = (response: IcpLeadResponse) => {
  const icpLead: IcpLead = {
    ...response,
    llmScoreSummary: response.llmScoreSummary ?? '',
    createdAt: dayjs(response.createdAt),
  }
  if (response.lastAction) {
    icpLead.outreachStage = {
      type: response.lastAction.type,
      status: response.lastAction.status ?? undefined,
    }
  }

  return icpLead
}

export const icpLeadsWithStatusAdapter = (response: IcpLeadsWithStatusResponse) => {
  const icpLeadsWithStatus: IcpLeadsWithStatus = {
    status: response.status,
    leads: response.leads.map(icpLeadAdapter),
    isOutreachAvailable: response.isOutreachAvailable,
    leadsLeft: response.leadsLeft,
  }

  return icpLeadsWithStatus
}
