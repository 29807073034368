import { Button } from '@onsaui'
import { IllustrationSpace } from '@onsaui/icons'

import { BillingPlanTier } from '@/modules/billing/model'
import { openContactSalesModal, openUpgradeToProModal } from '@/modules/billing/store/billingStore'
import useUserMe from '@/modules/user/hooks/useUserMe'
import AnalyticsService from '@/services/analytics'

const IcpLeadsLimitHandler: React.FC = () => {
  const { data: userMe } = useUserMe()

  const handleOpenPlanSelectModal = () => {
    const planTier = userMe?.planTier
    const from = 'IcpCampaignLeadsWidget'

    if (planTier === BillingPlanTier.FREE) {
      AnalyticsService.trackEvent('BtnUpgradeToPro.Click', { from })

      setTimeout(() => openUpgradeToProModal())
    }

    if (planTier === BillingPlanTier.PRO) {
      AnalyticsService.trackEvent('BtnContactSales.Click', { from })

      setTimeout(() => openContactSalesModal())
    }
  }

  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <IllustrationSpace />

      <h2 className="mb-4 mt-4 text-lg font-semibold">Oops...</h2>

      <p className="mb-4 max-w-sm whitespace-pre-line text-center">
        {userMe?.planTier === BillingPlanTier.FREE
          ? "You've reached the lead limit.\nUpgrade your plan to generate more prospects and expand your reach."
          : "You've reached the lead limit.\nUpgrade your plan to generate more prospects and expand your reach."}
      </p>
      <Button onClick={handleOpenPlanSelectModal}>
        {userMe?.planTier === BillingPlanTier.FREE ? 'Upgrade to Pro' : 'Contact Sales'}
      </Button>
    </div>
  )
}

export default IcpLeadsLimitHandler
