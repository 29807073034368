import { IllustrationIcp } from '@onsaui/icons'
import { useSearchParams } from 'react-router-dom'

import { openSignInModal } from '@/modules/auth'
import { BillingPlanTier } from '@/modules/billing/model'
import CreateTaskCard from '@/modules/general/components/CreateTaskCard/CreateTaskCard'
import { Icp } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

import CreateIcpForm from '../CreateIcpForm/CreateIcpForm'

const CreateIcpCard: React.FC<{
  onIcpCreated?: (icp: Icp) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
  tier?: BillingPlanTier
  freeIcp?: boolean
}> = ({ onIcpCreated, className, isAnon, creditsCost, tier, freeIcp }) => {
  const handleButtonClick = () => {
    if (isAnon) {
      AnalyticsService.trackEvent('PlaygroundPage.CreateIcpCard.BtnCreateIcp.Click')
      openSignInModal('/?create-icp=true')
    } else {
      AnalyticsService.trackEvent('CreateIcpCard.BtnCreateIcp.Click')
    }
  }

  const [searchParams] = useSearchParams()
  const autoOpen = searchParams.get('create-icp') === 'true'

  return (
    <CreateTaskCard
      className={className}
      price={tier === BillingPlanTier.PRO ? creditsCost : undefined}
      freeIcp={freeIcp || isAnon}
      beta
      Illustration={IllustrationIcp}
      title="Find and Reach"
      description="Discover ideal prospects and launch tailored outreach campaigns in minutes."
      buttonLabel="Search"
      onButtonClick={handleButtonClick}
      modalContent={!isAnon ? <CreateIcpForm onIcpCreated={onIcpCreated} isAnon={isAnon} /> : null}
      autoOpen={autoOpen}
    />
  )
}

export default CreateIcpCard
