import { Navigate, useLocation } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import NavigateToWorkspace from '@/modules/workspace/components/NavigateToWorkspace/NavigateToWorkspace'

const HomePage: React.FC = () => {
  const { isAuth } = useAuth()
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to="/playground" replace />
  }

  return <NavigateToWorkspace path={location.search} />
}

export default HomePage
