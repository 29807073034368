import { Dayjs } from 'dayjs'

import { TaskStatus } from '@/modules/task/model'

import { OutreachStageStatus, OutreachStageType } from './outreachInterfaces'

export interface IcpLeadsWithStatus {
  status: TaskStatus
  leads: IcpLead[]
  isOutreachAvailable: boolean
  leadsLeft: number
}

export interface IcpLead {
  id: string
  batchId: string
  createdAt: Dayjs
  fullName: string
  position: string
  linkedInUrl: string
  companyName: string
  companyLinkedInUrl: string
  score: number
  comment: string | null
  status: LeadStatus
  memoId: string | null
  message: string | null
  llmScoreSummary: string
  llmScore: number
  outreachStage?: {
    type: OutreachStageType
    status?: OutreachStageStatus
  }
}

export enum LeadStatus {
  New = 'new',
  Approved = 'approved',
  Deleted = 'deleted',
  Contacted = 'contacted',
}
