import React from 'react'
import { useNavigate } from 'react-router-dom'

import CreateIcpCard from '@/modules/icp/components/CreateIcpCard/CreateIcpCard'
import CreatePersonMemoTaskCard from '@/modules/task/components/CreatePersonMemoTaskCard/CreatePersonMemoTaskCard'
import CreateTranscriptReportTaskCard from '@/modules/task/components/CreateTranscriptReportTaskCard/CreateTranscriptReportTaskCard'
import { AnyTask } from '@/modules/task/model'

const PlaygroundPage: React.FC = () => {
  const navigate = useNavigate()
  const handleTaskCreated = (task: AnyTask) => {
    navigate(`/playground/t/${task.id}`)
  }

  return (
    <div className="my-auto flex flex-row flex-wrap justify-center gap-12 px-6 pb-8">
      <CreatePersonMemoTaskCard onTaskCreated={handleTaskCreated} isAnon />
      <CreateTranscriptReportTaskCard onTaskCreated={handleTaskCreated} isAnon />
      <CreateIcpCard onIcpCreated={() => {}} isAnon />
    </div>
  )
}

export default PlaygroundPage
