import { IllustrationPerson } from '@onsaui/icons'

import CreateTaskCard from '@/modules/general/components/CreateTaskCard/CreateTaskCard'
import { PersonMemo } from '@/modules/task/model'

import CreatePersonMemoTaskForm from '../CreatePersonMemoTaskForm/CreatePersonMemoTaskForm'

const CreatePersonMemoTaskCard: React.FC<{
  onTaskCreated?: (task: PersonMemo.Task) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
}> = ({ onTaskCreated, className, isAnon, creditsCost }) => {
  return (
    <CreateTaskCard
      className={className}
      price={creditsCost}
      Illustration={IllustrationPerson}
      title="Know Before Your Call"
      description="Generate a personalized memo with insights about the prospect & their company."
      buttonLabel="Create Memo"
      modalContent={<CreatePersonMemoTaskForm onTaskCreated={onTaskCreated} isAnon={isAnon} />}
    />
  )
}

export default CreatePersonMemoTaskCard
