import { Button, Loader } from '@onsaui'
import { IconRetrySm } from '@onsaui/icons'
import cx from 'clsx'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import { icpHelpers } from '@/modules/icp/model'
import { TaskStatus } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import IcpLoader from '../IcpLoader/IcpLoader'
import IcpLeadItem from './IcpLeadItem'
import IcpReferenceScore from './IcpReferenceScore'

const IcpReferenceLeadsWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const referenceLeadsQuery = useIcpReferenceLeads(icpId)
  const { generateIcpReferenceLeads, isGenerating } = useIcpLeadsActions()

  const { isGood } = icpHelpers.getLeadsScore(referenceLeadsQuery.data?.leads || [])
  const hasGenerateMoreButton = !isGood && referenceLeadsQuery.data?.status === TaskStatus.Completed

  const handleGenerateLeads = async () => {
    AnalyticsService.trackEvent('IcpReferenceLeadsWidget.BtnGenerateMoreLeads.Click')
    await generateIcpReferenceLeads(icpId)
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <div className="mb-5 flex min-h-10 flex-row items-center mobile:flex-col mobile:items-start mobile:gap-2">
        <h3 className="font-semibold">Score Prospects</h3>

        <div className="flex-grow" />

        {referenceLeadsQuery.data && referenceLeadsQuery.data.leads.length ? (
          <>
            <Button
              variant="primary"
              size="medium"
              className="mr-2"
              onClick={handleGenerateLeads}
              isDisabled={
                isGood || isGenerating || referenceLeadsQuery.data?.status !== TaskStatus.Completed
              }
            >
              <span className="mr-2">Generate More Leads</span>
              <IconRetrySm />
            </Button>
            <IcpReferenceScore icpId={icpId} />
          </>
        ) : null}
      </div>

      <RenderQuery query={referenceLeadsQuery}>
        {(leadsWithStatus) => {
          const batches = icpHelpers.getLeadsBatches(leadsWithStatus.leads)

          if (leadsWithStatus.leads?.length) {
            return (
              <div className="flex flex-col gap-4">
                {batches.map((batch, i) => {
                  return (
                    <>
                      {i > 0 && i === batches.length - 1 ? (
                        <div className="self-center rounded-xl bg-disabled-surface px-3 py-2 text-secondary">
                          <p>New Prospects</p>
                        </div>
                      ) : null}
                      {batch.map((lead) => (
                        <IcpLeadItem key={lead.id} icpId={icpId} icpLead={lead} />
                      ))}
                    </>
                  )
                })}

                {![TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) ? (
                  <div className="flex flex-col items-center justify-center py-4">
                    <p className="mb-4"> Loading more leads</p>
                    <Loader />
                  </div>
                ) : null}

                {hasGenerateMoreButton ? (
                  <Button
                    variant="primary"
                    size="medium"
                    className="self-center"
                    onClick={handleGenerateLeads}
                    isDisabled={
                      isGood ||
                      isGenerating ||
                      referenceLeadsQuery.data?.status !== TaskStatus.Completed
                    }
                  >
                    <span className="mr-2">Generate More Leads</span>
                    <IconRetrySm />
                  </Button>
                ) : null}
              </div>
            )
          }
          if (
            [TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) &&
            leadsWithStatus.leads?.length === 0
          ) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <h3 className="mb-4">We didn&apos;t find any relevant leads 🙁</h3>
                <Button type="button" onClick={handleGenerateLeads}>
                  Generate Again
                </Button>
              </div>
            )
          }
          return (
            <div className="flex flex-grow flex-col items-center justify-center">
              <IcpLoader stage="reference-leads" />
            </div>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default IcpReferenceLeadsWidget
