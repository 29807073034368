import { Button, Input } from '@onsaui'
import { IllustrationIcp } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { openSignInToContinueModal } from '@/modules/auth'
import { openUpgradeToProPromoModal } from '@/modules/billing/store/billingStore'
import useCreateIcp from '@/modules/icp/hooks/useCreateIcp'
import { Icp } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

const CreateIcpForm: React.FC<{
  onIcpCreated?: (icp: Icp) => void
  className?: string
  isAnon?: boolean
}> = ({ onIcpCreated, className, isAnon }) => {
  useEffect(() => {
    AnalyticsService.trackEvent('CreateIcpForm.View')
  }, [])

  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const [companyWebsiteUrl, setCompanyWebsite] = useState('')
  const handleCompanyUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyWebsite(e.target.value)
  }

  const handleLimitExeededError = () => {
    if (isAnon) {
      openSignInToContinueModal()
    } else {
      openUpgradeToProPromoModal()
    }
  }

  const { isCreating, createIcp } = useCreateIcp(handleLimitExeededError)

  const handleCreate = async (e: React.FormEvent) => {
    e.preventDefault()

    AnalyticsService.trackEvent('CreateIcpForm.BtnCreate.Click')

    const icp = await createIcp(companyWebsiteUrl)

    navigate(`/${workspaceId}/icp/${icp.id}`)
    onIcpCreated?.(icp)
  }

  return (
    <form className={cx('flex flex-col items-center', className)} onSubmit={handleCreate}>
      <IllustrationIcp className="mb-5" />
      <h3 className="mb-2 text-center font-semibold">Create ICP to Find Prospect Leads</h3>
      <p className="mb-3 text-center">
        Send me website link to the company you would like to find leads for
      </p>

      <p className="mb-5 text-center text-secondary">
        Note: You have a limit of 1 free ICP generation per month. Upgrade to Pro or contact sales
        for full outreach capabilities.
      </p>

      <div className="flex-grow" />

      <div className="flex w-full flex-col">
        <Input
          id="personUrl"
          className="mb-4 w-full"
          placeholder="Enter company website URL"
          value={companyWebsiteUrl}
          onChange={handleCompanyUrlChange}
        />

        <Button
          type="submit"
          className="w-full"
          isDisabled={!companyWebsiteUrl.trim().length || isCreating}
          isLoading={isCreating}
        >
          Search for Leads
        </Button>
      </div>
    </form>
  )
}

export default CreateIcpForm
