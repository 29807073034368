import { userApi } from '@/modules/user/api'
import { UserMe } from '@/modules/user/model'
import AnalyticsService from '@/services/analytics'
import { QueryService } from '@/services/query'

export const connectLinkedIn = async () => {
  const getRedirectUrl = () => {
    const url = new URL(window.location.href)
    url.searchParams.append('showLinkedInConnectionModal', 'true')

    return url.toString()
  }
  const redirectUrl = getRedirectUrl()

  const connectUrl = await userApi.connectLinkedIn(redirectUrl)

  if (!connectUrl) {
    throw new Error('Connection failed: no URL')
  }

  AnalyticsService.trackEvent('connectLinkedIn.Redirect')

  window.open(connectUrl, '_self')
}

export const disconnectLinkedIn = async () => {
  await userApi.disconnectLinkedIn()

  QueryService.getClient().setQueryData(['user_me'], (data?: UserMe) => {
    if (!data) {
      return data
    }

    return {
      ...data,
      linkedInConnection: undefined,
    }
  })
}

export const connectHubSpot = async () => {
  await userApi.connectHubSpot()
}

export const disconnectHubSpot = async () => {
  await userApi.disconnectHubSpot()
}
